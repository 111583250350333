<template>
	<div>
		<seller-branch-filter
			class="mb-3"
			labelText="รายงานสาขา"
			@changeBranch="changeBranch($event)">
		</seller-branch-filter>

		<h2>วิเคราะห์ธุรกิจ - รายงานสินค้าคงเหลือ</h2>

		<seller-report-date-filter class="mt-4" @changeFilterReportTable="changeFilterReportTable($event)" :reportName="reportName" :branchId="branchId" :isShowFilterStatus="true"></seller-report-date-filter>

		<div class="loading mt-2" v-if="!isResultReady">
			<loader-content :isDisplay="true"></loader-content>
		</div>

		<seller-report-table class="mt-2" :data="resultList" v-if="isResultReady"></seller-report-table>

		<paging
			@page="changePage($event)"
			:activePage="activePage"
			:totalPage="totalPage"
			class="mt-4">
		</paging>
	</div>
</template>

<script>
import SellerBranchFilter from '@/components/seller/BranchFilter';
import SellerReportDateFilter from '@/components/seller/report/DateFilter';
import SellerReportTable from '@/components/seller/report/Table';
import MixinReportTable from '@/components/seller/mixins/reportTable';

export default {
	mixins: [ MixinReportTable ],
	components: {
		SellerBranchFilter,
		SellerReportDateFilter,
		SellerReportTable
	},
	created() {
		this.reportName = 'product-remain';
	}
}
</script>

<style lang="scss" scoped>
@import '@/components/seller/style/Report.scss';
</style>